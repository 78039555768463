import { fetchData, postData } from "./api";

export const getAll = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getData = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getDataSlider = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getDataAchivments = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getDataServices = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getDataPartners = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getById = async (sort, id, data) =>
  await fetchData(`/api/${sort}/${id}`, data);

export const getByAlias = async (sort, alias) =>
  await fetchData(`/api/${sort}/${alias}`, null, null);

export const submitComment = async (data, sort) =>
  await postData(`/api/${sort}`, data, null);

export const fetchPageInfo = async (data) =>
  await fetchData(`/api/page/byurl`, data, null);

export const createNew = async (data, sort) =>
  await postData(`/api/${sort}`, data, null);

export const filterData = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const doSearch = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);
