import actions from "../../actions";
import { combineReducers } from "redux";

const {
  GET_ALL,
  ALL_RETURNED,
  GET_DATA,
  DATA_RETURNED,
  GET_DATA_SLIDER,
  DATA_SLIDER_RETURNED,
  GET_DATA_PROJECTS,
  DATA_PROJECTS_RETURNED,
  GET_DATA_PRODUCTS,
  DATA_PRODUCTS_RETURNED,
  ALL_CLEAR,
  GET_BY_ID,
  BY_ID_RETURNED,
  GET_BY_ALIAS,
  GET_DATA_PARTNERS,
  DATA_PARTNERS_RETURNED,
  BY_ALIAS_RETURNED,
  SUBMIT_COMMENT,
  SUBMIT_COMMENT_RETURNED,
  CREATE_NEW,
  CREATE_NEW_RETURNED,
  GET_DATA_ACHIVMENTS,
  DATA_ACHIVMENTS_RETURNED,
  CLEAR,
  GET_DATA_SERVICES,
  DATA_SERVICES_RETURNED,
  GET_PAGE_DETAILS,
  PAGE_DETAILS_RETURNED,
  FILTER_DATA,
  CLEAR_FILTER,
  FILTER_RETURNED,
  CLEAR_SEARCH_RESULTS,
  SEARCH,
  SEARCH_RETURNED,
} = actions;

export const getAll = (state = false, action) => {
  switch (action.type) {
    case GET_ALL:
      return true;
    case ALL_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_RETURNED:
      return {
        ...state,
        [action?.subSort ? action?.subSort : action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getData = (state = false, action) => {
  switch (action.type) {
    case GET_DATA:
      return true;
    case DATA_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getProjectsData = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_PROJECTS:
      return true;
    case DATA_PROJECTS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataProjectsReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_PROJECTS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getProductsData = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_PRODUCTS:
      return true;
    case DATA_PRODUCTS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataProductsReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_PRODUCTS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};
export const getDataAchivments = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_ACHIVMENTS:
      return true;
    case DATA_ACHIVMENTS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataAchivmentsReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_ACHIVMENTS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getDataServices = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_SERVICES:
      return true;
    case DATA_SERVICES_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataServicesReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_SERVICES_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getDataPartners = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_PARTNERS:
      return true;
    case DATA_PARTNERS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataPartnersReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_PARTNERS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getDataSlider = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_SLIDER:
      return true;
    case DATA_SLIDER_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataSliderReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_SLIDER_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getById = (state = false, action) => {
  switch (action.type) {
    case GET_BY_ID:
      return true;
    case BY_ID_RETURNED:
      return false;
    default:
      return state;
  }
};
export const byIdReturned = (state = false, action) => {
  switch (action.type) {
    case BY_ID_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getByAlias = (state = false, action) => {
  switch (action.type) {
    case GET_BY_ALIAS:
      return true;
    case BY_ALIAS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const byAliasReturned = (state = false, action) => {
  switch (action.type) {
    case BY_ALIAS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const submitComment = (state = false, action) => {
  switch (action.type) {
    case SUBMIT_COMMENT:
      return true;
    case SUBMIT_COMMENT_RETURNED:
      return false;
    default:
      return state;
  }
};
export const submitCommentReturned = (state = false, action) => {
  switch (action.type) {
    case SUBMIT_COMMENT_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getPageInfo = (state = false, action) => {
  switch (action.type) {
    case GET_PAGE_DETAILS:
      return true;
    case PAGE_DETAILS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const pageInfoReturned = (state = false, action) => {
  switch (action.type) {
    case PAGE_DETAILS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const createNew = (state = false, action) => {
  switch (action.type) {
    case CREATE_NEW:
      return true;
    case CREATE_NEW_RETURNED:
      return false;
    default:
      return state;
  }
};
export const createNewReturned = (state = [], action) => {
  switch (action.type) {
    case CREATE_NEW_RETURNED:
      return { data: action.data, sort: action.sort };
    case CLEAR:
      return [];

    default:
      return state;
  }
};

export const filterData = (state = false, action) => {
  switch (action.type) {
    case FILTER_DATA:
      return true;
    case FILTER_RETURNED:
      return false;
    default:
      return state;
  }
};
export const filterReturned = (state = [], action) => {
  switch (action.type) {
    case FILTER_RETURNED:
      return {
        ...state,
        data: action?.data,
        count: action.data.count,
      };

    case CLEAR_FILTER:
      return [];
    default:
      return state;
  }
};

export const search = (state = false, action) => {
  switch (action.type) {
    case SEARCH:
      return true;
    case SEARCH_RETURNED:
      return false;
    default:
      return state;
  }
};
export const searchReturned = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RETURNED:
      return {
        ...state,
        data: action?.data,
        count: action.data.count,
      };

    case CLEAR_SEARCH_RESULTS:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  createNew,
  createNewReturned,
  getAll,
  allReturned,
  getData,
  dataReturned,
  getProjectsData,
  dataProjectsReturned,
  getProductsData,
  dataProductsReturned,

  getById,
  byIdReturned,
  getByAlias,
  byAliasReturned,
  submitComment,
  submitCommentReturned,
  getDataSlider,
  dataSliderReturned,
  getDataAchivments,
  dataAchivmentsReturned,
  getDataServices,
  dataServicesReturned,
  getDataPartners,
  dataPartnersReturned,
  getPageInfo,
  pageInfoReturned,
  filterData,
  filterReturned,
  searchReturned,
  search,
});
