import { createAction } from "../creators";

export default {
  ...createAction("GET_NOTIFICATIONS", "sort", "limit", "offset"),
  ...createAction("ALL_NOTIFICATIONS_RETURNED", "data"),
  ...createAction("CLEAR", "data"),
  ...createAction("ALL_ITEMS_RETURNED", "sort", "subSort", "data"),
  ...createAction("ALL_CLEAR", "data"),
  ...createAction("GET_ALL", "data", "sort", "subSort"),
  ...createAction("ALL_RETURNED", "data", "subSort", "sort"),

  ...createAction("GET_DATA", "data", "sort"),
  ...createAction("DATA_RETURNED", "data"),

  ...createAction("GET_DATA_PROJECTS", "data", "sort"),
  ...createAction("DATA_PROJECTS_RETURNED", "data"),

  ...createAction("GET_DATA_PRODUCTS", "data", "sort"),
  ...createAction("DATA_PRODUCTS_RETURNED", "data"),

  ...createAction("GET_DATA_ACHIVMENTS", "data", "sort"),
  ...createAction("DATA_ACHIVMENTS_RETURNED", "data"),

  ...createAction("GET_DATA_SERVICES", "data", "sort"),
  ...createAction("DATA_SERVICES_RETURNED", "data"),

  ...createAction("GET_DATA_PARTNERS", "data", "sort"),
  ...createAction("DATA_PARTNERS_RETURNED", "data"),

  ...createAction("GET_DATA_SLIDER", "data", "sort"),
  ...createAction("DATA_SLIDER_RETURNED", "data"),

  ...createAction("GET_BY_ID", "sort", "id", "data"),
  ...createAction("BY_ID_RETURNED", "sort", "data"),

  ...createAction("GET_BY_ALIAS", "sort", "alias"),
  ...createAction("BY_ALIAS_RETURNED", "sort", "subSort", "data"),

  ...createAction("SUBMIT_COMMENT", "data", "sort"),
  ...createAction("SUBMIT_COMMENT_RETURNED", "data"),

  ...createAction("GET_PAGE_DETAILS", "data"),
  ...createAction("PAGE_DETAILS_RETURNED", "data"),

  ...createAction("CREATE_NEW", "data", "sort"),
  ...createAction("CREATE_NEW_RETURNED", "data", "sort"),

  ...createAction("CLEAR_FILTER", "data"),
  ...createAction("FILTER_DATA", "data", "sort"),
  ...createAction("FILTER_RETURNED", "data"),

  ...createAction("CLEAR_SEARCH_RESULTS", "data"),
  ...createAction("SEARCH", "data", "sort"),
  ...createAction("SEARCH_RETURNED", "data"),
};
