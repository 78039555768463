import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/index";
import BounceLoader from "react-spinners/BounceLoader";
ReactDOM.render(
  <Suspense
    fallback={
      <div
        style={{
          display: "flex",
          top: 0,
          bottom: 0,
          zIndex: 5500,
          backdropFilter: "blur(2px)",
          right: 0,
          backgroundColor: "#ffffff1a",
          left: 0,
          position: "fixed",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BounceLoader
          color={"rgb(112, 90, 177)"}
          loading={true}
          size={50}
          speedMultiplier={1}
        />
      </div>
    }
  >
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
