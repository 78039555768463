import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { buildCanonicalUrl } from "../component/shared/utils";
import { setDirection } from "../redux/actionCreators/theme";
import Routes from "./Routes";
import BounceLoader from "react-spinners/BounceLoader";

function Index() {
  // const dispatch = useDispatch();
  // const { i18n } = useTranslation();
  // const [spinner, setSpinner] = useState(false);
  // const isRTL = useSelector((state) => state.theme_reducer.basicTheme.isRTL);
  // const currentLanguage = isRTL ? "ar" : "en";
  // const { pathname: currentUrl } = useLocation();

  // useEffect(() => {
  //   (async () => {
  //     let { canonicalUrl, canonicalLanguage } = await buildCanonicalUrl(
  //       currentUrl,
  //       currentLanguage
  //     );
  //     if (currentLanguage !== canonicalLanguage) {
  //       dispatch(setDirection({ isRTL: !isRTL }));
  //       i18n.changeLanguage(isRTL ? "en" : "ar");
  //     }

  //     if (canonicalUrl === currentUrl) {
  //       return;
  //     }
  //     window.location.replace(canonicalUrl);
  //   })();
  // }, [currentUrl]);

  // useEffect(() => {
  //   const urlParts = currentUrl.split("/").filter(Boolean);
  //   const currentLanguage = urlParts[0];
  //   if (currentLanguage === "en" || currentLanguage === "ar") setSpinner(false);
  //   else setSpinner(true);
  // }, [currentUrl]);

  return <Routes />;
}

export default Index;
