export const makeTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    palette: {
      background: {
        default: "#fff",
      },
      blue: {
        primary: currentTheme.elements.colors.lightBlue,
      },
      slider: {
        header: currentTheme.elements.fontSize.xll,
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.secondary,
      },
      textSecondary: {
        main: currentTheme.elements.colors.textSecondary,
      },
      cards: {
        main: currentTheme.elements.colors.cards,
      },
    },
    globals: currentTheme.elements,
  };
};
export const darkTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",

    palette: {
      type: "dark",
      background: {
        default: "#0c0a0a",
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.secondary,
      },
      black: {
        main: "#fff",
      },
      gray: {
        main: "#fff",
      },
    },
    globals: {
      ...currentTheme.elements,
      colors: {
        ...currentTheme.elements.colors,
        bgWhite: "#000",
        white: "#fff",
        label: "#fff",
        lightGray: "#fff",
        blackColor: "#4668c5",
        darkBlack: "#fff",
        gray: "#565656",
        bgGray: "#323232",
        textMed: "#4668c5",
        grayBorder: "#ffffff3b",
        homeSectionTitle: "#fff",
        blackColor: "#000",
        purpuleColor: "#fff",
        black: "#fff",
        disableText: "rgba(255,255,255, 0.6)",
      },
    },
  };
};

export const blindTheme = ({ currentTheme, isRTL }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    type: "blind",
    palette: {
      type: "blind",
      background: {
        default: "#fff",
      },
      blue: {
        primary: currentTheme.elements.colors.lightBlue,
      },
      slider: {
        header: currentTheme.elements.fontSize.xll,
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.secondary,
      },
      textSecondary: {
        main: currentTheme.elements.colors.textSecondary,
      },
      cards: {
        main: currentTheme.elements.colors.cards,
      },
    },

    globals: { ...currentTheme.elements, direction: isRTL ? "rtl" : "ltr" },
  };
};

export const blueTheme = ({ currentTheme, isRTL }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    type: "blue",
    palette: {
      type: "blue",
      background: {
        default: "#fff",
      },
      blue: {
        primary: currentTheme.elements.colors.lightBlue,
      },
      slider: {
        header: currentTheme.elements.fontSize.xll,
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.secondary,
      },
      textSecondary: {
        main: currentTheme.elements.colors.textSecondary,
      },
      cards: {
        main: currentTheme.elements.colors.cards,
      },
    },

    globals: { ...currentTheme.elements, direction: isRTL ? "rtl" : "ltr" },
  };
};
