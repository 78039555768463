import { put, takeLatest, call } from "redux-saga/effects";
import { subScribe } from "../../network/subscribe";
import actions from "../../actions";

const { SUBSCRIBE, subscribeComplete } = actions;

function* performSubscribe(action) {
  try {
    let data = action.payload;

    const result = yield call(subScribe, data);

    if (result) {
      yield put(subscribeComplete({ data: result.data }));
    } else {
      yield put(subscribeComplete({ data: [] }));
    }
  } catch (e) {
    yield put(subscribeComplete({ data: [] }));

    return;
  }
}
export function* watchGetSubscribe() {
  yield takeLatest(SUBSCRIBE, performSubscribe);
}
