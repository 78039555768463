import actions from "../../actions";
import { combineReducers } from "redux";

const {
  APPLY_JOB,
  APPLY_JOB_DONE,
  GET_RELATED_CAREERS,
  GET_RELATED_CAREERS_DONE,
} = actions;
export const applyJob = (state = false, action) => {
  switch (action.type) {
    case APPLY_JOB:
      return true;
    case APPLY_JOB_DONE:
      return false;
    default:
      return state;
  }
};

export const applyJobDone = (state = null, action) => {
  switch (action.type) {
    case APPLY_JOB_DONE:
      return action.data;
    default:
      return state;
  }
};

export const relatedCareers = (state = false, action) => {
  switch (action.type) {
    case GET_RELATED_CAREERS:
      return true;
    case GET_RELATED_CAREERS_DONE:
      return false;
    default:
      return state;
  }
};
export const relatedCareersDone = (state = [], action) => {
  switch (action.type) {
    case GET_RELATED_CAREERS_DONE:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  applyJob,
  applyJobDone,
  relatedCareers,
  relatedCareersDone,
});
