import { put, takeLatest, call } from "redux-saga/effects";
import { applyJob, fetchRelatedCareers } from "../../network/career";
import actions from "../../actions";
import { uploadFile, createFile } from "../../network/files";

const { APPLY_JOB, applyJobDone, GET_RELATED_CAREERS, getRelatedCareersDone } =
  actions;

function* performApplyJob(action) {
  const idForImage = [];
  const idForCV = [];
  try {
    let data = action.payload;
    const image = data?.image;
    const file = data?.cv;
    // upload personal image
    if (data?.image && data?.image?.length > 0) {
      var formData = new FormData();
      image.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          ...file,
        });
      });

      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => idForImage?.push(files.id));
    }

    // upload cv
    if (data?.cv && data?.cv?.length > 0) {
      var formData = new FormData();
      file.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          ...file,
        });
      });

      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => idForCV?.push(files.id));
    }

    // apply for a job
    const result = yield call(applyJob, {
      ...action.payload,
      photoId: idForCV[0],
      cvId: idForImage[0],
    });
    if (result) {
      yield put(applyJobDone({ data: result.data }));
    } else {
      yield put(applyJobDone({ data: [] }));
    }
  } catch (e) {
    yield put(applyJobDone({ data: [] }));
    return;
  }
}

export function* watchGetApplyJob() {
  yield takeLatest(APPLY_JOB, performApplyJob);
}

function* performRelatedCareers(action) {
  try {
    const result = yield call(fetchRelatedCareers, action.payload);
    if (result) {
      yield put(getRelatedCareersDone({ data: result }));
    } else {
      yield put(getRelatedCareersDone({ data: [] }));
    }
  } catch (e) {
    yield put(getRelatedCareersDone({ data: [] }));
    return;
  }
}

export function* watchRelatedCareers() {
  yield takeLatest(GET_RELATED_CAREERS, performRelatedCareers);
}
