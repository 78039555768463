import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import actions from "../../actions";
import {
  getAll,
  getById,
  getByAlias,
  submitComment,
  getData,
  getDataSlider,
  getDataAchivments,
  getDataServices,
  getDataPartners,
  fetchPageInfo,
  createNew,
  filterData,
  doSearch,
} from "../../network/crud";
const {
  GET_ALL,
  allReturned,
  GET_BY_ID,
  byIdReturned,
  GET_BY_ALIAS,
  byAliasReturned,
  SUBMIT_COMMENT,
  submitCommentReturned,
  GET_DATA,
  dataReturned,
  GET_DATA_PROJECTS,
  dataProjectsReturned,
  GET_DATA_PRODUCTS,
  dataProductsReturned,
  GET_DATA_SLIDER,
  dataSliderReturned,
  GET_DATA_ACHIVMENTS,
  dataAchivmentsReturned,
  GET_DATA_SERVICES,
  dataServicesReturned,
  GET_DATA_PARTNERS,
  dataPartnersReturned,
  GET_PAGE_DETAILS,
  pageDetailsReturned,
  CREATE_NEW,
  createNewReturned,
  FILTER_DATA,
  filterReturned,
  SEARCH,
  searchReturned,
} = actions;

function* performGetAll(action) {
  try {
    let sort = action.sort;
    let subSort = action?.data?.type;
    let data = action.data;
    const result = yield call(getAll, data, sort);
    if (result && result.res.success) {
      yield put(allReturned({ data: result.res, sort, subSort }));
    } else {
      yield put(allReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetAll() {
  yield takeEvery(GET_ALL, performGetAll);
}

function* performGetData(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getData, data, sort);
    if (result && result?.res && result?.res?.success) {
      yield put(dataReturned({ data: result.res, sort }));
    } else {
      yield put(dataReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetData() {
  yield takeEvery(GET_DATA, performGetData);
}
function* performGetProjectsData(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getData, data, sort);
    if (result && result?.res && result?.res?.success) {
      yield put(dataProjectsReturned({ data: result.res, sort }));
    } else {
      yield put(dataProjectsReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetProjectData() {
  yield takeEvery(GET_DATA_PROJECTS, performGetProjectsData);
}

function* performGetProductsData(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getData, data, sort);
    if (result && result?.res && result?.res?.success) {
      yield put(dataProductsReturned({ data: result.res, sort }));
    } else {
      yield put(dataProductsReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetProductsData() {
  yield takeEvery(GET_DATA_PRODUCTS, performGetProductsData);
}

function* performGetAchivments(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getDataAchivments, data, sort);
    if (result && result?.res && result?.res?.success) {
      yield put(dataAchivmentsReturned({ data: result.res, sort }));
    } else {
      yield put(dataAchivmentsReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetAchivments() {
  yield takeEvery(GET_DATA_ACHIVMENTS, performGetAchivments);
}

function* performGetServices(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getDataServices, data, sort);
    if (result && result.res.success) {
      yield put(dataServicesReturned({ data: result.res, sort }));
    } else {
      yield put(dataServicesReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetServices() {
  yield takeEvery(GET_DATA_SERVICES, performGetServices);
}

function* performGetPartners(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getDataPartners, data, sort);
    if (result && result.res.success) {
      yield put(dataPartnersReturned({ data: result.res, sort }));
    } else {
      yield put(dataPartnersReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetPartners() {
  yield takeEvery(GET_DATA_PARTNERS, performGetPartners);
}

function* performGetDataSlider(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(getDataSlider, data, sort);
    if (result && result.res.success) {
      yield put(dataSliderReturned({ data: result.res, sort }));
    } else {
      yield put(dataSliderReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchGetDataSlider() {
  yield takeEvery(GET_DATA_SLIDER, performGetDataSlider);
}

function* performGetById(action) {
  try {
    let id = action.id;
    let sort = action.sort;
    let data = action.data;
    const result = yield call(getById, sort, id, data);

    if (result)
      yield put(
        byIdReturned({
          data: { result: result.res },
        })
      );
    else yield put(byIdReturned({ data: [] }));
  } catch (error) {
    yield put(byIdReturned({ data: [] }));
  }
}

export function* watchGetById() {
  yield takeEvery(GET_BY_ID, performGetById);
}
function* performGetByAlias(action) {
  try {
    const { alias, sort } = action;

    const result = yield call(getByAlias, sort, alias);
    if (result) {
      yield put(byAliasReturned({ data: result.res }));
    } else yield put(byAliasReturned({ data: [] }));
  } catch (error) {}
}

export function* watchGetByAlias() {
  yield takeEvery(GET_BY_ALIAS, performGetByAlias);
}

function* performSubmitComment(action) {
  try {
    let data = action.data;
    let sort = action.sort;
    const result = yield call(submitComment, data, sort);

    if (result) {
      yield put(submitCommentReturned({ data: result.data }));
    } else {
      yield put(submitCommentReturned({ data: [] }));
    }
  } catch (error) {}
}
export function* watchSubmitComment() {
  yield takeLatest(SUBMIT_COMMENT, performSubmitComment);
}

function* performGetPageInfo(action) {
  try {
    const data = action.data;
    const result = yield call(fetchPageInfo, data);

    if (result) yield put(pageDetailsReturned({ data: result.res }));
    else yield put(pageDetailsReturned({ data: [] }));
  } catch (error) {}
}

export function* watchGetPageInfo() {
  yield takeLatest(GET_PAGE_DETAILS, performGetPageInfo);
}

function* performCreateNew(action) {
  try {
    let data = action.data;
    let sort = action.sort;
    const result = yield call(createNew, data, sort);

    if (result) {
      yield put(createNewReturned({ data: result.data, sort: sort }));
    } else {
      yield put(createNewReturned({ data: [] }));
    }
  } catch (error) {}
}
export function* watchCreateNew() {
  yield takeLatest(CREATE_NEW, performCreateNew);
}

function* performFilterData(action) {
  try {
    let sort = action.sort;

    let data = action.data;
    const result = yield call(filterData, data, sort);
    if (result && result.res.success) {
      yield put(filterReturned({ data: result.res, sort }));
    } else {
      yield put(filterReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchFilterData() {
  yield takeEvery(FILTER_DATA, performFilterData);
}

function* performSearch(action) {
  try {
    let sort = action.sort;
    let data = action.data;
    const result = yield call(doSearch, data, sort);
    if (result && result.res.success) {
      yield put(searchReturned({ data: result.res, sort }));
    } else {
      yield put(searchReturned({ data: [] }));
    }
  } catch (error) {}
}

export function* watchSearch() {
  yield takeEvery(SEARCH, performSearch);
}
