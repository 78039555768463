import { CssBaseline, Paper } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import {
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@mui/styles";
import BounceLoader from "react-spinners/BounceLoader";
import { create } from "jss";
import rtl from "jss-rtl";
import React, {
  lazy,
  memo,
  Suspense,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/actions";
import { useNavigate } from "react-router";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { makeTheme, darkTheme } from "../styles/globalTheme/makeTheme";
const Projects = lazy(() => import("../pages/projects/Projects"));
const JoinUs = lazy(() => import("../pages/joinUs/JoinUs"));
const Footer = lazy(() => import("../component/footer/Footer"));
const Header = lazy(() => import("../pages/header/Header"));
const Helpful = lazy(() => import("../component/common/helpful/Helpful"));
const SearchResults = lazy(() =>
  import("../pages/searchResults/SearchResults")
);
const TagsResults = lazy(() => import("../pages/tags/Tags"));
const Home = lazy(() => import("../pages/home/Home"));
const WhoComponent = lazy(() => import("../component/whoWeAre/WhoWeAre"));
const Subscribe = lazy(() => import("../pages/subscribe/Subscribe"));
const Services = lazy(() => import("../pages/Services"));
const ContactUs = lazy(() => import("../pages/contactUs/ContactUs"));
const Vision = lazy(() => import("../pages/aboutus/Vision"));
const Methodology = lazy(() => import("../pages/aboutus/Methodology"));
const VideoGallery = lazy(() => import("../pages/mediaCenter/VideoGallery"));
const MediaCenter = lazy(() => import("../pages/mediaCenter/MediaCenterPage"));
const VideoGalleryDetails = lazy(() =>
  import("../pages/mediaCenter/VideoGalleryDetails")
);
const Project = lazy(() => import("../pages/projects/Project"));
const News = lazy(() => import("../pages/news/News"));
const Publications = lazy(() => import("../pages/publications/publications"));
const Photos = lazy(() => import("../pages/photos/Photos"));
const PhotoGalleryDetails = lazy(() =>
  import("../pages/photos/PhotoGalleryDetails")
);
const BasicPage = lazy(() => import("../pages/basicPage/BasicPage"));
const CategoriesPage = lazy(() =>
  import("../pages/categoriesPage/CategoriesPage")
);
// const Product = lazy(() => import("../pages/products/ProductDetails"));
const Products = lazy(() => import("../pages/products/Products"));
const SiteMap = lazy(() => import("../pages/siteMap/SiteMap"));
const Product = lazy(() => import("../pages/products/ProductDetails"));
const SpesificService = lazy(() => import("../pages/SpesificService"));
const Careers = lazy(() => import("../pages/careers/Careers"));
const CareerDetails = lazy(() => import("../pages/careers/CareerDetails"));
const RecruitmentForm = lazy(() => import("../pages/careers/RecruitmentForm"));
const Partner = lazy(() => import("../component/partner/Partner"));
const OurPartners = lazy(() => import("../pages/ourPartners/OurPartners"));
const PrivacyPolicy = lazy(() =>
  import("../pages/privacyPolicy/PrivacyPolicy")
);
const Disclaimer = lazy(() => import("../pages/disclaimer/Disclaimer"));
const TermsAndConditions = lazy(() =>
  import("../pages/termsAndConditions/TermsAndConditions")
);
const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const Events = lazy(() => import("../pages/events/Events"));
const Survey = lazy(() => import("../pages/survey/Survey"));
const SurveyDetails = lazy(() => import("../pages/survey/SurveyDetails"));
const ExitForm = lazy(() => import("../component/common/exitForm/ExitForm"));
function Routing() {
  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getData, getAll } = actions;

  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [headerMenuItems, setHeaderMenuItems] = useState([]);
  const [footerMenuItems, setFooterMenuItems] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);
  const reducer = useSelector((state) => state);
  window.navigateTo = navigate;
  window.dispatch = dispatch;
  window.currentLocation = location;
  window.navigateToDefault = () => window.navigateTo("/home");
  const componentRef = useRef();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const theme = isDark ? darkTheme(basicTheme) : makeTheme(basicTheme);
  const materialTheme = createTheme(theme);
  const useStyles = makeStyles((materialTheme) => ({
    spinner: {
      display: "flex",
      top: 0,
      bottom: 0,
      zIndex: 5500,
      backdropFilter: "blur(2px)",
      right: 0,
      backgroundColor: "#ffffff1a",
      left: 0,
      position: "fixed",
      justifyContent: "center",
      alignItems: "center",
    },
    floatingSocial: {
      position: "fixed",
      right: isRTL ? "unset" : 0,
      left: isRTL ? 0 : "unset",
      top: "55vh",
      zIndex: "10",
      zIndex: 1000,
      [materialTheme?.breakpoints?.down(750)]: {
        top: "52vh",
      },
      [materialTheme?.breakpoints?.down(500)]: {
        top: "47vh",
      },
      [materialTheme?.breakpoints?.down(390)]: {
        height: 90,
        width: 60,
      },
      [materialTheme?.breakpoints?.down(365)]: {
        height: 80,
        width: 50,
      },
      [materialTheme?.breakpoints?.down(340)]: {
        height: 70,
        width: 40,
      },
      "& div.btnsContainer": {
        height: 100,
        width: 70,
        background: theme.globals.colors.primary,

        clipPath: isRTL
          ? "polygon(0 0, 100% 0, 70% 100%, 0 100%)"
          : "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
        display: "grid",
        placeItems: "center",

        [materialTheme?.breakpoints?.down(750)]: {
          height: 80,
          width: 70,
        },
        [materialTheme?.breakpoints?.down(365)]: {
          height: 80,
          width: 55,
        },
        [materialTheme?.breakpoints?.down(340)]: {
          height: 80,
          width: 65,
          paddingLeft: theme.direction === "rtl" ? 16 : 0,
        },
        "& span": {
          cursor: "pointer",
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: isRTL ? 15 : 0,
          [materialTheme?.breakpoints?.down(340)]: {
            marginRight: 0,
          },
          "& a": {
            marginLeft: isRTL ? 0 : 20,
            [materialTheme?.breakpoints?.down(750)]: {
              height: 35,
              width: 32,
            },
            [materialTheme?.breakpoints?.down(365)]: {
              height: 35,
              width: 27,
              marginLeft: isRTL ? 0 : 6,
            },
            [materialTheme?.breakpoints?.down(340)]: {
              marginLeft: isRTL ? 0 : -6,
            },
          },
          "& svg": {
            fontSize: theme.globals.fontSize.xl + 4,
            color: theme.globals.colors.white,
            marginLeft: isRTL ? 0 : "26px",
            transform: "scale(1)",
            transition: "transform 0.4s",

            [materialTheme?.breakpoints?.down(750)]: {
              fontSize: theme.globals.fontSize.xl - 2,
            },
            [materialTheme?.breakpoints?.down(365)]: {
              fontSize: theme.globals.fontSize.s + 2,
            },
          },

          "&:hover svg": {
            transform: "scale(1.2)",
          },
        },

        "& span:first-of-type": {
          "& svg": {
            marginLeft: isRTL ? 0 : "13px",
            [materialTheme?.breakpoints?.down(365)]: {
              marginLeft: 0,
            },
            [materialTheme?.breakpoints?.down(340)]: {
              marginLeft: "-10px",
            },
          },
        },
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    let sort = "menuItem";
    let data = {};
    dispatch(getAll({ data, sort }));
  }, [isRTL]);
  useEffect(() => {
    let sort = "configuration";
    let data = {};
    dispatch(getData({ data, sort }));
  }, [isRTL]);

  useEffect(() => {
    if (
      reducer?.crud?.getAll ||
      reducer?.crud?.getData ||
      reducer?.crud?.getByAlias ||
      reducer?.crud?.createNew ||
      reducer?.surveys?.getSurveyByAlias ||
      reducer?.surveys?.submitAnswer ||
      reducer?.polls?.submitPollsReducer ||
      reducer?.polls?.getSurveyAnswers ||
      reducer?.filterPosts?.filterPosts ||
      reducer?.search?.getResults ||
      reducer?.login?.loginReducer ||
      reducer?.login?.resetPasswordReducer ||
      reducer?.login?.forgetPasswordReducer ||
      reducer?.login?.editProfileReducer ||
      reducer?.crud.getDataSlider ||
      reducer?.crud.getById ||
      reducer?.crud.getDataAchivments ||
      reducer?.crud.getDataServices ||
      reducer?.crud.getProjectsData ||
      reducer?.crud.getProductsData ||
      reducer?.crud.submitComment ||
      reducer?.contactusReducer.contactUs ||
      reducer?.subscribeReducer.subScribe ||
      reducer?.crud?.filterData ||
      reducer?.crud?.search ||
      reducer?.career?.applyJob
    )
      setToggleSpinner(true);
    else setToggleSpinner(false);
  }, [reducer]);

  useEffect(() => {
    if (reducer?.crud?.allReturned.menuItems) {
      setMenuItems(reducer?.crud?.allReturned?.menuItems);
    }
  }, [reducer.crud?.allReturned.menuItems]);

  useEffect(() => {
    if (menuItems && menuItems?.length > 0) {
      setHeaderMenuItems(
        menuItems.filter(
          (item) => item?.title === "الرئيسية" || item?.title === "header"
        )
      );
      setFooterMenuItems(
        menuItems.filter(
          (item) =>
            item?.title?.includes("الفوتر") || item?.title?.includes("footer")
        )
      );
      setQuickLinks(
        menuItems?.filter(
          (item) =>
            item?.title?.includes("روابط سريعة") ||
            item?.title?.includes("QuickLinks")
        )
      );
    }
  }, [menuItems]);
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        <Paper
          ref={componentRef}
          display="flex"
          flex-direction="column"
          width="100%"
          dir={isRTL ? "rtl" : "ltr"}
          id="full-page"
          style={{
            background: materialTheme.palette.background.default,
            position: "relative",
            minHeight: "100vh",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",

            filter:
              theme && theme?.globals?.type === "blind"
                ? "grayscale(100%)"
                : "inherit",
          }}
        >
          <Suspense fallback={<h1></h1>}>
            <Header />
            <Routes>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route
                path="/products"
                element={<Products displayBanner={true} />}
              />
              <Route path="/view-product/:alias" element={<Product />} />
              <Route path="/photos" element={<Photos displayBanner={true} />} />
              <Route path="/home" element={<Home />} />
              <Route path="/projects/:alias" element={<Project />} />
              <Route path="/join-us" element={<JoinUs />} />
              <Route path="/our-methodology" element={<Methodology />} />
              <Route path="/services" element={<Services />} />
              <Route path="/siteMap" element={<SiteMap />} />
              <Route path="/who-we-are" element={<WhoComponent />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/vision-mission" element={<Vision />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/videos" element={<VideoGallery />} />
              <Route path="/media-center" element={<MediaCenter />} />
              <Route
                path="/publications"
                element={<Publications displayBanner={true} />}
              />
              <Route
                path="/videos/:alias"
                element={<VideoGalleryDetails displayBanner={true} />}
              />
              <Route path="/search-results" element={<SearchResults />} />
              <Route path="/tags" element={<TagsResults />} />

              <Route path="/products/:alias" element={<Product />} />
              <Route path="/photos" element={<Photos displayBanner={true} />} />
              <Route
                path={`/photos/:alias`}
                element={<PhotoGalleryDetails displayBanner={true} />}
              />
              <Route
                path={`/page/:alias`}
                element={<BasicPage displayBanner={true} />}
              />
              <Route path={`/section/:alias`} element={<CategoriesPage />} />
              <Route
                path={`/:type/:alias`}
                element={<BasicPage displayBanner={true} />}
              />
              {/* <Route path="/projects/:alias" element={<Project />} /> */}
              <Route path="/products/:alias" element={<Product />} />
              <Route path="/events" element={<Events displayBanner={true} />} />
              <Route path="/survey" element={<Survey displayBanner={true} />} />
              <Route path={`/survey/:alias`} element={<SurveyDetails />} />
              <Route path="/services/:alias" element={<SpesificService />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/careers/:alias" element={<CareerDetails />} />
              <Route
                path="/careers/recruitment-form/:alias"
                element={<RecruitmentForm />}
              />
              <Route path="/partner/:alias" element={<Partner />} />
              <Route path="/our-partners" element={<OurPartners />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/news" element={<News displayBanner={true} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {!window?.location?.href?.includes("home") && <Helpful />}
            <Footer
              menuItems={footerMenuItems.flatMap((item) => item?.childrens)}
              quickLinks={quickLinks.flatMap((item) => item?.childrens)}
            />
            <ExitForm />
          </Suspense>
        </Paper>
        {toggleSpinner ? (
          <div className={classes.spinner}>
            <BounceLoader
              color={"rgb(112, 90, 177)"}
              loading={true}
              size={50}
              speedMultiplier={1}
            />
          </div>
        ) : null}
      </ThemeProvider>
    </StylesProvider>
  );
}

export default memo(Routing);
