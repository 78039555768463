import { put, takeLatest, call } from "redux-saga/effects";
import { contactUs } from "../../network/contactus";
import actions from "../../actions";

const { CONTACT_US, contactUsComplete } = actions;

function* performContactUs(action) {
  try {
    let data = action.payload;

    const result = yield call(contactUs, data);
    if (result) {
      yield put(contactUsComplete({ data: result.data }));
    } else {
      yield put(contactUsComplete({ data: [] }));
    }
  } catch (e) {
    yield put(contactUsComplete({ data: [] }));

    return;
  }
}
export function* watchGetContactus() {
  yield takeLatest(CONTACT_US, performContactUs);
}
