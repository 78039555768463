import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import theme_reducer from "./theme";
import contactusReducer from "./groups/contactus";
import subscribeReducer from "./groups/subscribe";
import career from "./groups/career";
import crud from "./groups/crud";
import surveys from "./groups/survey";
import polls from "./groups/polls";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme_reducer,
    contactusReducer,
    subscribeReducer,
    crud,
    career,
    surveys,
    polls,
  });

export default rootReducer;
