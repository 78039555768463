import theme from "./groups/theme";
import contactus from "./groups/contactus";
import subscribe from "./groups/subscribe";
import crud from "./groups/crudActions";
import career from "./groups/career";
import survey from "./groups/survey";
import polls from "./groups/polls";

export default {
  ...theme,
  ...contactus,
  ...subscribe,
  ...career,
  ...crud,
  ...survey,
  ...polls,
};
