import { fetchData, postData, fetchBinaryData } from "./api";
import axios from "axios";

export const uploadFile = async (data) => {
  try {
    const res = await axios.post(`/api/file/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    return false;
  }
};

export const createFile = async (data) => await postData(`/api/file`, data, {});
export const getFileById = async (id) =>
  await fetchData(`/api/file/${id}`, null, {});

export const updateFile = async (data, id) =>
  await postData(`/api/file/${id}/update`, data, {});

export const downloadFile = async (uuid) =>
  await fetchBinaryData(`/api/file/download/${uuid}`, null, {});

export const downloadImage = async (uuid) =>
  await fetchBinaryData(`/api/file/download/${uuid}`, null, {});
