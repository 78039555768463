import { fork } from "redux-saga/effects";
import * as contactusSagas from "./groups/contactus";
import * as subscribeSagas from "./groups/subscribe";
import * as crudSagas from "./groups/crud";
import * as careerSagas from "./groups/career";
import * as surveySagas from "./groups/survey";
import * as pollsSagas from "./groups/polls";

const sagas = Object.values({
  ...contactusSagas,
  ...subscribeSagas,
  ...crudSagas,
  ...careerSagas,
  ...surveySagas,
  ...pollsSagas,
});

export default function* rootSaga(getState) {
  for (let index = 0; index < sagas.length; index++) {
    yield fork(sagas[index]);
  }
}
