import actions from "../../actions";
import { combineReducers } from "redux";
const { SUBSCRIBE, SUBSCRIBE_COMPLETE } = actions;
export const subScribe = (state = false, action) => {
  switch (action.type) {
    case SUBSCRIBE:
      return true;
    case SUBSCRIBE_COMPLETE:
      return false;
    default:
      return state;
  }
};

export const subScribeComplete = (state = null, action) => {
  switch (action.type) {
    case SUBSCRIBE_COMPLETE:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  subScribe,
  subScribeComplete,
});
