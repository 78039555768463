import actions from "../../actions";
import { combineReducers } from "redux";
const { CONTACT_US, CONTACT_US_COMPLETE } = actions;
export const contactUs = (state = false, action) => {
  switch (action.type) {
    case CONTACT_US:
      return true;
    case CONTACT_US_COMPLETE:
      return false;
    default:
      return state;
  }
};

export const contactUsComplete = (state = null, action) => {
  switch (action.type) {
    case CONTACT_US_COMPLETE:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  contactUs,
  contactUsComplete,
});
